/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import { CardMedia, colors } from '@mui/material';


import Container from 'components/Container';

import p1 from 'assests/Photos/CoCurricular/School_camps_2022_23.jpg';
import p2 from 'assests/Photos/CoCurricular/inter_school_event_competation_2022_23.jpg';
import p3 from 'assests/Photos/CoCurricular/CCA_banner.jpg';

// import pdf1 from 'assests/Pdf/CCA/Associationactivity2021-22.pdf';
// import pdf2 from 'assests/Pdf/CCA/INTERSCHOOLPPT2021-22.pdf';

const mock = [
  {
    media: p1,
    color: colors.lightBlue[500],
    title: '1. School camps – Excursions/Field Trips',
    subtitle: '',
    href: '/disaster-managment',
  },
  {
    media: p2,
    color: colors.lightBlue[500],
    title: '2. Inter-school Competition - Goonj',
    subtitle: '',
    href: '/school-program',
  },
  {
    media: p3,
    color: colors.lightBlue[500],
    title: '3. CCA and Club activities',
    subtitle: '',
    href: '/clubs',
  },
  // {
  //   color: colors.lightBlue[500],
  //   title: 'Student Council led events – Teachers Day',
  //   subtitle: '',
  //   href: '',
  // },
  // {
  //   color: colors.purple[500],
  //   title: 'Almanac',
  //   subtitle: '',
  //   href: 'https://www.npsypr.com/pdf/2020_21/resources/almanac_2021.pdf',
  
  // },
  // {
  //   color: colors.yellow[500],
  //   title: 'Staff Details',
  //   subtitle: '',
  //   href: 'https://www.npsypr.com/pdf/2020_21/resources/staff_details.pdf',

  // },
  // {
  //   color: colors.indigo[500],
  //   title: 'Text book List',
  //   subtitle: '',
  //   href: 'https://www.npsypr.com/pdf/2020_21/resources/list_of_text_book.pdf',

  // },
  // {
  //   color: colors.red[500],
  //   title: 'Declaration',
  //   subtitle: '',
  //   href: 'https://www.npsypr.com/pdf/2020_21/resources/books_declaration.pdf',
    
   
  // },
  // {
  //   color: colors.green[500],
  //   title: 'Fee Declaration',
  //   subtitle: '',
  //   href: 'https://www.npsypr.com/pdf/2020_21/resources/fee_declaration.pdf',
   
  // },
];

const Resources = () => {
  return (

    <Box >

      <Container>
        <Grid container spacing={8}>
          {mock.map((item, i) => (
            <Grid item xs={12} sm={8} md={4} key={i}>
              <Box
                
                bgcolor={'alternate.main'}>
                <Box component={Card} padding={4} width={1} height={1}  bgcolor={'alternate.main'}>
                  <Box display={'flex'} flexDirection={'column'}>
                  <CardMedia
                  image={item.media}
                  component = 'img'
                  sx={{
                    height: 240,
                  }}
                />
                    <Typography
                      variant={'h6'}
                      gutterBottom
                      sx={{ fontWeight: 700 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {item.subtitle}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box
                      marginTop={2}
                      display={'flex'}
                      justifyContent={'flex-end'}
                    >
                      <Button
                        href ={item.href}
                        endIcon={
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </Box>
                        }
                      >
                        Learn More
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>

  );
};

export default Resources;
